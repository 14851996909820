import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationSubUnitsPageComponent } from "./pages/organization-sub-units-page/organization-sub-units-page.component";
import { EvDialogModule } from "ev-dialog";
import { EvKeyValuePairModule } from "ev-key-value-pair";
import { EvLoaderModule } from "ev-loader";
import { SharedModule } from "../shared/shared.module";
import { EvInputReactiveModule } from "ev-input-reactive";
import { ConnectedEstateUnitsService } from '../shared/services/connected-estate-units.service';

@NgModule({
  declarations: [OrganizationSubUnitsPageComponent],
  imports: [
    CommonModule,
    EvDialogModule,
    EvKeyValuePairModule,
    EvLoaderModule,
    EvInputReactiveModule,
    SharedModule
  ],
  providers: [ConnectedEstateUnitsService],
})
export class OrganizationSubUnitsModule { }
