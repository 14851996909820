export const environment = {
  production: true,
  auth: {
      clientId: '#{{CLIENT_ID}}#',
      authority: '#{{AUTHORITY}}#',
      redirectUri: '#{{REDIRECT_URI}}#',
  },
  apiUrl: '#{{API_URL}}#',
  apiAccessScope: '#{{API_ACCESS_SCOPE}}#',
  staticMapApiKey: '0420312a-121d-4139-bd48-16ca91e9866b',
  portalApi: '#{{PORTAL_URI}}#',
  connectionString: '#{{EV_APP_INSIGHTS}}#' //TODO: Replace with correct key
};
