import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { CommercialRentPageComponent } from './pages/commercial-rent-page/commercial-rent-page.component';
import { SearchRentComponent } from './components/search-rent/search-rent.component';
import { CommercialSaleModule } from "../commercial-sale/commercial-sale.module";

@NgModule({
    declarations: [
        CommercialRentPageComponent,
        SearchRentComponent
    ],
    imports: [
        SharedModule,
        CommercialSaleModule
    ]
})
export class CommercialRentModule { }
