import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, RoutesRecognized } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private router: Router) {
    router.events.subscribe((res) => {
      if (res instanceof RoutesRecognized) {
        let route = res.state.root.firstChild;
        this.pageTitle$.next(route.data.title);
      }
      this.setShowNav(false);
    });
  }

  getShowNav(){
    return this.showNav$.asObservable();
  }

  setShowNav(showHide: boolean) {
    this.showNav$.next(showHide);
  }

  toggleNavState() {
    this.showNav$.next(!this.showNav$.value);
  }

  isNavOpen() {
    return this.showNav$.value;
  }
}
