<div class="body" [ngClass]="dialog ? 'dialog' : 'standard'">
  <div *ngIf="!loading" class="search-results">
    <table *ngIf="realEstates && realEstates.length > 0; else noResult">
      <tr class="row-header">
        <th [class.selected]="selectedSortKey === SortKey.Type" (click)="sortEstates(SortKey.Type)"class="type">Type</th>
        <th [class.selected]="selectedSortKey === SortKey.Address" (click)="sortEstates(SortKey.Address)" class="address">Adresse</th>
        <th [class.selected]="selectedSortKey === SortKey.Municipality" (click)="sortEstates(SortKey.Municipality)">Kommune</th>
        <th [class.selected]="selectedSortKey === SortKey.Cadastre" (click)="sortEstates(SortKey.Cadastre)">Matrikkel</th>
      </tr>
      <tr title="{{'uid: ' + realEstate.unitId}}" *ngFor="let realEstate of realEstates" (click)="setSelectedRealEstate(realEstate)">
        <td><div class="estate-icon" [ngClass]="EVEstateType[realEstate.estateTypeId]"></div></td>
        <td class="address">{{ realEstate.fullAddress }}</td>
        <td>{{ realEstate.municipalityName }}</td>
        <td>{{ realEstate.cadastre }}</td>
      </tr>
    </table>
    <ng-template #noResult>
      <p class="message">Ingen treff</p>
    </ng-template>
  </div>
  <div *ngIf="loading" class="search-results loader">
    <ev-loader [size]="110"></ev-loader>
  </div>
</div>

