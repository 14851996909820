import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { MapMarkerUtil } from 'src/app/shared/components/static-map/static-map-marker.util';
import { MapMarker } from 'src/app/shared/components/static-map/static-map.component';
import { Unit } from "../../../commercial-sale/models/unit.model";
import { EstatesService } from "../../../commercial-sale/services/estates.service";
import { OrganizationSubUnit } from "../../../dashboard/models/organization-sub-units";
import { WorkQueueItem } from "../../../shared/models/workqueue-item.model";
import { WorkQueueSourceTableId } from "../../../shared/models/workqueue-source-table-id.enum";
import { WorkQueueTypeId } from "../../../shared/models/workqueue-type-id.enum";
import { ConnectedEstateUnitsService } from '../../../shared/services/connected-estate-units.service';
import { ToastService } from "../../../shared/services/toast.service";
import { WorkQueueService } from "../../../shared/services/workqueue.service";
import { OrganizationSubUnitsService } from "../../services/organization-sub-units.service";

@Component({
  selector: 'organization-sub-units-page',
  templateUrl: './organization-sub-units-page.component.html',
  styleUrls: ['./organization-sub-units-page.component.scss']
})
export class OrganizationSubUnitsPageComponent implements OnInit {

  connectedEstateUnits$: Observable<Unit[]>;
  connectedEstateUnitsMapMarkers$: Observable<MapMarker[]>;

  organizationSubUnitsForm = new UntypedFormGroup({
    webPageUrl: new UntypedFormControl(null),
    visitAddress: new UntypedFormControl(null)
  });
  
  loadingAddressSearch: boolean;
  loadingNextWorkqueueItem: boolean = false;
  showSearchUnitDialog: boolean = false;
  isSaving: boolean = false;

  selectedOrganization: OrganizationSubUnit;
  estateResult: Unit[];
  currentWorkItem: WorkQueueItem = null;
  remainingWorkqueue: number | string = '  ';

  private orgUnitId: number;

  constructor(
    private organizationSubUnitsService: OrganizationSubUnitsService,
    private activatedRoute: ActivatedRoute,
    private estateService: EstatesService,
    private toastService: ToastService,
    private workqueueService: WorkQueueService,
    private router: Router,
    private connectedEstateUnitsService:  ConnectedEstateUnitsService
    ) {}

  ngOnInit(): void {
    this.setConnectedEstateUnits([]);
    this.connectedEstateUnits$ = this.connectedEstateUnitsService.connectedSaleUnits$;
    this.connectedEstateUnitsMapMarkers$ = this.connectedEstateUnitsService.connectedSaleUnits$
      .pipe(map(units => {return MapMarkerUtil.convertUnitsToMapMarker(units)}), map(units => {return units.filter(u => u.lat && u.lng)} ));

    this.activatedRoute.params.subscribe( params => {
      this.getRemainingWorkQueue();
      this.selectedOrganization = null;

      if(params?.id != null) {
        this.currentWorkItem = params as WorkQueueItem;
      } else {
        this.currentWorkItem = null;
      }

      if(params['orgUnitId'] != null) {
        this.orgUnitId = Number.parseInt(params['orgUnitId']);

        this.loadingNextWorkqueueItem = true;    
        this.getSubUnitData();
      }
    })
  }

  searchAddress() {
    const searchInput = this.organizationSubUnitsForm.controls['visitAddress'].value
    if(searchInput !== '' && searchInput != null && !this.loadingAddressSearch) {
      this.loadingAddressSearch = true;

      this.estateService.searchEstates(searchInput).subscribe(
        (response) => {
          this.loadingAddressSearch = false;
          this.estateResult = response;
        },
        (error) => {
          this.loadingAddressSearch = false;
          this.toastService.setMessage({ text: 'Skjedd en feil søk på address. Prøv på nytt', type: 'error'});
        })
    }
  }

  openWebPage() {
    let url: string = this.organizationSubUnitsForm.controls['webPageUrl'].value;
    const addHttp = url.trim().startsWith('http') ? url : 'https://' + url;

    window.open(addHttp, "_blank");
  }

  putCommonWorkQueueHold() {
    this.workqueueService.putCommonWorkQueueHold(WorkQueueSourceTableId.OrganizationSubUnits, this.currentWorkItem.sourceTableIdentificationId).subscribe(() => {
      this.organizationSubUnitsForm.reset();
      this.router.navigate(['organizationsubunits']);
    });
  }

  getNextFromWorkQueue() {
    this.loadingNextWorkqueueItem = true;
    this.resetState();
    this.workqueueService.getCommonWorkQueueNext(WorkQueueSourceTableId.OrganizationSubUnits, WorkQueueTypeId.OrganizationSubUnits).subscribe(
      workItem => {
        this.navigateToWorkItem(workItem.sourceTableIdentificationId, workItem)},
      error => {this.toastService.setMessage({text: 'Noe feil har skjedd ved å hente workqueue. Prøv igjen', type: 'error'})},
      () => {this.loadingNextWorkqueueItem = false;});
  }


  navigateToWorkItem(orgUnitId: number | string, workItem? : WorkQueueItem) {
    this.showSearchUnitDialog = false;

    if(workItem != null) {
      if(this.orgUnitId != orgUnitId) {
        this.router.navigate(['organizationsubunits/'+ orgUnitId, workItem]);
      } else {
        this.getSubUnitData();
      }

    } else {
      if(this.orgUnitId != orgUnitId) {
        this.router.navigate(['organizationsubunits/', orgUnitId]);
      }
      else {
        this.getSubUnitData();
      }
    }
  }

  saveOrganizationSubUnit() {
    this.isSaving = true;
    this.organizationSubUnitsService.updateOrganizationSubUnitWithEstateUnits(
      this.orgUnitId, 
      this.organizationSubUnitsForm.controls['webPageUrl'].value,
      this.connectedEstateUnitsService.getAddedConnectedUnitsId(),
      this.connectedEstateUnitsService.getRemovedUnitsId()
      ).pipe(
        concatMap(() => this.setWQProcessed()),
        catchError(error => {
          this.toastService.setMessage({text: 'Det oppstod en feil ved lagring: ' + error, type: 'error'});
          this.isSaving = false;
          return of({});
        })
      ).subscribe(res => {
        this.router.navigate(['organizationsubunits/' + this.orgUnitId]);
        this.isSaving = false;
    },
      error => {this.toastService.setMessage({text: 'Noe feil har skjedd ved å lagre. Prøv igjen', type: 'error'})})
  }

  cancel() {
    if(this.currentWorkItem != null && this.currentWorkItem.sourceTableIdentificationId) {
      this.workqueueService.putCommonWorkQueueReset(WorkQueueSourceTableId.OrganizationSubUnits, this.currentWorkItem.sourceTableIdentificationId)
        .subscribe(() => {
          this.resetState();
          this.router.navigate(['organizationsubunits']);
        });
    } else {
      this.resetState();
      this.router.navigate(['organizationsubunits']);
    }
  }

  addConnectedRealEstate(unit: Unit) {
    this.connectedEstateUnitsService.addConnectedUnit(unit);
  }

  deleteConnectedRealEstate(unit: Unit) {
    this.connectedEstateUnitsService.removeConnectedUnit(unit);
  }

  splitVisitAddress(visitAddress: string){
    if(visitAddress)
    {
      const splittedAddress = visitAddress?.split('-'); 

      return splittedAddress[0];
    }

    return ''
  }

  private getSubUnitData() {
    this.organizationSubUnitsService.getOrganizationSubUnitWithUnits(this.orgUnitId).subscribe(orgUnit => {
      this.organizationSubUnitsForm.patchValue(orgUnit);
      this.setConnectedEstateUnits(orgUnit.unitIds);
      this.selectedOrganization = orgUnit;
      this.loadingNextWorkqueueItem = false;
    });
  }

  private setConnectedEstateUnits(units: Unit[]) {
    this.connectedEstateUnitsService.setConnectedUnitList(units);
  }

  private resetState() {
    this.selectedOrganization = null;
    this.setConnectedEstateUnits([]);
    this.estateResult = null;
    this.isSaving = false;
  }

  private getRemainingWorkQueue() {
    this.workqueueService.getCommonWorkQueueRemaining(WorkQueueSourceTableId.OrganizationSubUnits).subscribe(queue => this.remainingWorkqueue = queue);
  }

  private setWQProcessed(): Observable<any> {
    if(this.currentWorkItem != null && this.currentWorkItem.id != null) {
      return this.workqueueService.putCommonWorkQueueProcessed(WorkQueueSourceTableId.OrganizationSubUnits, this.currentWorkItem.sourceTableIdentificationId);
    } else {
      return of(1);
    }
  }
}
