import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsightsService } from '../shared/services/application-insights.service';

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
    constructor(private appInsightService: ApplicationInsightsService, private router: Router) {}

    handleError(error: any): void {
        error.message = `${error.message}, url: ${this.router.url}`
        console.error(error);   
        this.appInsightService.logException(error);
    }
}
