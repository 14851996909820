import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Message } from "../models/message";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private messages$: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);

  constructor() { }

  setMessage(message: Message) {
    const messages: Message[] = [message];
    this.messages$.next(messages);
  }

  getMessages() {
    return this.messages$.asObservable();
  }

  deleteMessage(message: Message) {
    const messages: Message[] = this.messages$.getValue();
    messages.forEach((value,index) => {
      if(value == message) messages.splice(index,1);
    });
    this.messages$.next(messages);
  }
}
