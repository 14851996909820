import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent {

  @Input() title: string;
  @Input() headers: Header[];
  @Input() rows: any;

}

export interface Header {
  title: string;
  key: string;
  textAlign: string;
}
