<button class="connect" (click)="showConnectToRealEstate = true">Koble til eiendom</button>

<ev-dialog [visible]="showConnectToRealEstate" (close)="showConnectToRealEstate = false" [theme]="'green'" [title]="'Søk etter eiendom'" [tooltip]="searchEstateTooltip" [hideFooter]="true">
    <div class="search-real-estate">
        <div class="header">
            <ev-input-reactive type="search" [formCtrl]="searchForm.get('query')" (keyup.enter)="searchRealEstate()"> </ev-input-reactive>
            <button class="search" (click)="searchRealEstate()"></button>
        </div>
      <real-estate-result [loading]="loading" [realEstates]="realEstates" [dialog]="true" (selectedRealEstate)="setSelectedRealEstate($event)"></real-estate-result>

    </div>
</ev-dialog>
