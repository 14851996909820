import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { ToastService } from "../../../shared/services/toast.service";
import { OrganizationSubUnitsService } from "../../../organization-sub-units/services/organization-sub-units.service";

@Component({
  selector: 'search-unit',
  templateUrl: './search-unit.component.html',
  styleUrls: ['./search-unit.component.scss']
})
export class SearchUnitComponent {
  @Output() selectedSubUnit: EventEmitter<SubUnitResponse> = new EventEmitter();

  query = new UntypedFormControl('');
  searchForm = this.formBuilder.group({ query: this.query });
  searchResult: SubUnitResponse[] = [];
  loading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private organizationSubUnitsService: OrganizationSubUnitsService
  ) { }

  search(): void {
    this.searchResult = [];
    if(this.query.value !== '' && this.query.value != null) {
      this.loading = true;
      this.organizationSubUnitsService.searchOrganizationSubUnits(this.query.value).subscribe(
        (res) => {
          this.searchResult = res;
          this.loading = false;
          },
        error => {this.loading = false; this.toastService.setMessage({text: 'Noe feil har skjedd ved søk. Prøv igjen', type: 'error'})},
      )}
  }

  onSelectSubUnit(unit: SubUnitResponse | undefined): void {
    this.selectedSubUnit.emit(unit);
  }

}

export interface SubUnitResponse {
  organizationNumber: number,
  organizationName: string,
  organizationMunicipality: string,
  id: number
}
