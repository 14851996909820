
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkQueueItem } from '../models/workqueue-item.model';
import { Observable } from 'rxjs';
import { WorkQueueSourceTableId } from '../models/workqueue-source-table-id.enum';
import { WorkQueueTypeId } from '../models/workqueue-type-id.enum';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class WorkQueueService {
    constructor(private http: HttpClient) { }

    /**
     * Get the next work item from the queue, based on user level
     * @param tableId 
     * @param typeId 
     */
    getCommonWorkQueueNext(tableId: WorkQueueSourceTableId, typeId: WorkQueueTypeId): Observable<WorkQueueItem> {
        return this.http.get<{data: WorkQueueItem}>(`${environment.apiUrl}/Common/WorkQueue/${tableId}/Next?workQueueTypeId=${typeId}`).pipe(map(p => p.data));
    }

    /**
     * Get the remaining number of items in the queue, based on user level
     * @param tableId 
     */
    getCommonWorkQueueRemaining(tableId: WorkQueueSourceTableId): Observable<number> {
        return this.http.get<{data : {sourceTableID: number, remainingRows: number}}>(`${environment.apiUrl}/Common/WorkQueue/${tableId}/Remaining`).pipe(map(p => p.data.remainingRows));
    }

    /**
     * Put work item back in queue again
     * @param tableId 
     * @param sourceTableIdentificationId 
     */
    putCommonWorkQueueReset(tableId: WorkQueueSourceTableId, sourceTableIdentificationId: string | number): Observable<any> {
        return this.http.put(`${environment.apiUrl}/Common/WorkQueue/${tableId}/Reset`, {sourceTableIdentificationId: sourceTableIdentificationId.toString()});
    }

    /**
     * Escalate work item to next expert level
     * 
     * @param tableId 
     * @param sourceTableIdentificationId 
     */
    putCommonWorkQueueHold(tableId: WorkQueueSourceTableId, sourceTableIdentificationId: string | number): Observable<any> {
        return this.http.put(`${environment.apiUrl}/Common/WorkQueue/${tableId}/Hold`, {sourceTableIdentificationId: sourceTableIdentificationId.toString()});
    }

    /**
     * Called when a workqueue item is processed and finished
     * 
     * @param tableId 
     * @param sourceTableIdentificationId 
     */
    putCommonWorkQueueProcessed(tableId: WorkQueueSourceTableId, sourceTableIdentificationId: string | number): Observable<any> {
        return this.http.put(`${environment.apiUrl}/Common/WorkQueue/${tableId}/Processed`, {sourceTableIdentificationId: sourceTableIdentificationId.toString()});
    }
}