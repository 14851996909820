import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Unit } from "../../../commercial-sale/models/unit.model";
import { EVEstateType } from '../../enums/estatetype.enum';

@Component({
  selector: 'real-estate-result',
  templateUrl: './real-estate-result.component.html',
  styleUrls: ['./real-estate-result.component.scss']
})
export class RealEstateResultComponent implements OnChanges {

  @Input() realEstates: Unit[];
  @Input() loading: boolean;
  @Input() size: number;
  @Input() dialog: boolean;

  @Output() selectedRealEstate = new EventEmitter<Unit>();

  EVEstateType = EVEstateType;
  SortKey = SortKey;

  selectedSortKey: SortKey = SortKey.Address;
  private _sortDirectionAscend: boolean = true;

  ngOnChanges(changes: SimpleChanges) {
    if(changes.realEstates && changes.realEstates.currentValue != null && changes.realEstates.currentValue.length > 1) {
      this.sortEstates(this.selectedSortKey, true);
    }
  }

  setSelectedRealEstate(realEstate: Unit) {
    this.selectedRealEstate.emit(realEstate);
  }

  sortEstates(sortKey: SortKey, sortDirectionAscend?: boolean) {
    if(sortDirectionAscend != null) {
      this._sortDirectionAscend = sortDirectionAscend;
    } else {
      this._sortDirectionAscend = sortKey != this.selectedSortKey ? true : ! this._sortDirectionAscend;
    }

    this.selectedSortKey = sortKey;

    this.realEstates.sort((a,b) => 
      this.compare(a, b, sortKey, this._sortDirectionAscend)
    );
  }

  private compare(a: any, b: any, sortKey: string, ascend: boolean = true): number {
    if(a[sortKey] === b[sortKey]) {
      return 0;
    }

    if(ascend)
        return a[sortKey] < b[sortKey]? -1 : 1;
    else 
        return a[sortKey] < b[sortKey]? 1 : -1;
  }
}

export enum SortKey {
  Type = "estateTypeId",
  Address = "fullAddress",
  Cadastre = "cadastre",
  Municipality = "municipalityName"
}