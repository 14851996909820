<div class="component-wrapper">
    <div class="search-wrapper">
        <ev-input-reactive type="search"  [formCtrl]="searchForm.get('query')" (keyup.enter)="search()"> </ev-input-reactive>
        <button class="search" (click)="search()"></button>
    </div>
    <div *ngIf="!loading" class="search-result-wrapper">
        <div *ngIf="searchResult && searchResult.length > 0; else noResult" class="address-header">
          <div class="type-heading">Type</div>
          <div class="address-heading">Adresse</div>
        </div>
        <ng-template #noResult>
          <div class="no-result">Ingen resultater</div>
        </ng-template>
        <div *ngFor="let addressGroup of searchResult" class="address-group">
            <div class="address">
                <div class="estate-icon" [ngClass]="iconClass(addressGroup.saleList[0]?.estateTypeId)"></div>
                <div>
                    {{ addressGroup.address }}
                </div>
            </div>
            <div *ngFor="let item of addressGroup.saleList" class="item">
                <span>
                    {{ (item.informationDate | date: "d MMM y") + " - " + item.askingPrice }}
                    <a class="finn-link" [href]="item.sourceURL" target="_blank">
                        <u>{{ SourceTypeId[item.sourceTypeId] + ": " + item.sourceInformationIdentification }}</u>
                    </a>
                </span>
                <button class="light" (click)="onSelectSale(item.estateSaleId)">velg</button>
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="search-result-wrapper loader">
        <ev-loader [size]="110"></ev-loader>
    </div>
</div>
