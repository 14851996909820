import { NgModule } from '@angular/core';
import { CommercialSaleService } from './services/commercial-sale.service';
import { CommercialSalePageComponent } from './pages/commercial-sale-page/commercial-sale-page.component';
import { SharedModule } from '../shared/shared.module';
import { ConnectedEstateUnitsService } from '../shared/services/connected-estate-units.service';
import { VersionsComponent } from './pages/commercial-sale-page/components/versions/versions.component';
import { AdTextComponent } from '../shared/components/ad-text/finn-text.component';

@NgModule({
    declarations: [CommercialSalePageComponent, VersionsComponent, AdTextComponent, VersionsComponent],
    imports: [SharedModule],
    exports: [CommercialSalePageComponent, VersionsComponent, AdTextComponent],
    providers: [CommercialSaleService, ConnectedEstateUnitsService],
})
export class CommercialSaleModule {}
