import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoRegistrationPageComponent } from './pages/photo-registration-page/photo-registration-page.component';
import { SharedModule } from "../shared/shared.module";
import { SearchEstateResultsComponent } from "./components/search-estate-results/search-estate-results.component";
import { EvFileUploadModule } from "ev-file-upload";
import { EvDirectivesModule } from "ev-directives";

@NgModule({
  declarations: [
    PhotoRegistrationPageComponent,
    SearchEstateResultsComponent,
    SearchEstateResultsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EvDirectivesModule,
    EvFileUploadModule
  ]
})
export class PhotoRegistrationModule { }
