import { Component } from '@angular/core';
import { NavigationService } from "./shared/services/navigation.service";
import { Message } from './shared/models/message';
import { ToastService } from "./shared/services/toast.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ev-toolkit-web';

  messages: Message[] = [];
  pageTitle: string;

  constructor(private navService: NavigationService, private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.getMessages().subscribe(messages => this.messages = messages)
    this.navService.pageTitle$.subscribe(title => {
      this.pageTitle = title
    })
  }

  toggleSideNav() {
    this.navService.setShowNav(true);
  }

  removeMessage(message: Message) {
    this.toastService.deleteMessage(message)
  }
}


