import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import {map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnergyLabel, EnergyLabelSearch, EnergyLabelConnection, EnergyLabelCreateResponse } from '../models/energy-label.model';

@Injectable()
export class EnergyLabelService {
  
    constructor(private http: HttpClient) { }

    getEnergyLabel(energyLabelInfoID: string): Observable<EnergyLabel> {
        return this.http.get<{ data:EnergyLabel }>(`${environment.apiUrl}/EnergyLabel/EnergyPerformanceCertificate?energyLabelInfoID=${energyLabelInfoID}`)
            .pipe(map(result => result.data));
    }

    createEnergyLabel(energyLabel: EnergyLabel): Observable<EnergyLabelCreateResponse> {
        return this.http.post<{ data:EnergyLabelCreateResponse }>(`${environment.apiUrl}/EnergyLabel/EnergyPerformanceCertificate`, energyLabel)
            .pipe(map(result => result.data));
    }

    updateEnergyLabel(energyLabel: EnergyLabel): Observable<void> {
        return this.http.put<void>(`${environment.apiUrl}/EnergyLabel/EnergyPerformanceCertificate`, energyLabel);
    }

    searchEnergyLabel(query: string): Observable<EnergyLabelSearch[]> {
        return this.http.get<{data:EnergyLabelSearch[]}>(`${environment.apiUrl}/EnergyLabel/SearchEnergyPerformanceCertificates?query=${query}`)            
            .pipe(map(result => result.data));
    }

    searchEnergyLabelConnections(query: string): Observable<EnergyLabelConnection[]> {
        return this.http.get<{data:EnergyLabelConnection[]}>(`${environment.apiUrl}/EnergyLabel/SearchEnergyCertificateConnections?query=${query}`)            
            .pipe(map(result => result.data));
    }
}