<div *ngIf="realEstates && realEstates.length > 0">
  <table>
    <tr class="row-header">
      <th>Adresse</th>
      <th>Matrikkel</th>
    </tr>
    <tr title="{{'uid: ' + realEstate.unitId}}" *ngFor="let realEstate of realEstates" (click)="setSelectedRealEstate(realEstate)">
      <td>{{ realEstate.streetName }} {{ realEstate.streetNumber }}{{ realEstate.streetLetter }}, {{ realEstate.postalCode }} {{ realEstate.postOffice }}</td>
      <td>{{ realEstate.cadastre }}</td>
    </tr>
  </table>
</div>

