<div class="search-wrapper">
    <ev-input-reactive type="search" [formCtrl]="searchForm.get('query')" (keyup.enter)="search()"> </ev-input-reactive>
    <button class="search" (click)="search()"></button>
</div>
<div class="search-result-wrapper">
  @if (loading) {
    <div class="loader">
      <ev-loader [size]="110"></ev-loader>
    </div>
  }

    @if (searchResult.length > 0) {
    <div class="column-wrapper column-title">
        <div class="column">Dato</div>
        <div class="column">Attestnummer</div>
        <div class="column">Eiendom</div>
        <div class="column">Bygning</div>
        <div class="column">Bruksenhet</div>
    </div>
    @for (result of searchResult; track $index) {
    <div class="column-wrapper clickeble" (click)="selectEnergy(result.energyLabelInfoID)" >
        <div class="column">
            {{ result.issueDate | date:'d.MM.yyyy' }}
        </div>
        <div class="column">
            {{ result.energyLabelID}}
        </div>
        <div class="column">
            {{ result.estate }}
        </div>
        <div class="column">
          {{ result.building }}
        </div>
        <div class="column">
          {{ result.functionalUnit }}
        </div>
    </div>
    } }
</div>

