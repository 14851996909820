import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { RouterModule } from "@angular/router";
import { EvTooltipModule } from "ev-tooltip";

@NgModule({
  declarations: [DashboardPageComponent],
  imports: [
    CommonModule,
    RouterModule,
    EvTooltipModule
  ]
})

export class DashboardModule { }
