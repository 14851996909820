export enum EVEstateType {
    Enebolig = 2,
    Rekkehus = 3,
    Leilighet = 4,
    Fritidsbolig = 6,
    Tomannsbolig = 7,
    Skole = 10,
    Næring = 11,
    Landbruk = 12,
    Garasje = 17,
    Ubebygd = 18,
    Offentlig = 20,
    Helse = 21,
    MediaKomm = 22,
    KulturIdrett = 23,
    Religion = 24,
    Transport = 25,
    Annet = 27
}