<button class="open" (click)="showModal = !showModal">Søk</button>

<ev-dialog [visible]="showModal" (close)="showModal = false" [theme]="'green'" [hideFooter]="true" [title]="title" [tooltip]="searchAgentTooltip">
    <div class="search-agent">
        <div class="header">
            <ev-input-reactive type="search"  [formCtrl]="searchForm.get('query')" (keyup.enter)="searchAgent()"> </ev-input-reactive>
            <button class="search" (click)="searchAgent()"></button>
        </div>
        <div class="body">
            <div *ngIf="!loading" class="search-results">
                <table *ngIf="agents && agents.length > 0; else noResult">
                    <tr class="row-header">
                        <th><div class="agentIcon"></div></th>
                        <th class="name">Navn</th>
                    </tr>
                    <tr *ngFor="let agent of agents" (click)="setSelectedAgent(agent)">
                        <td><div class="agent-icon"></div></td>
                        <td class="agent">{{ agent.name }}</td>
                    </tr>
                </table>
                <ng-template #noResult>
                    <p class="message">Ingen treff</p>
                </ng-template>
            </div>
            <div *ngIf="loading" class="search-results loader">
                <ev-loader [size]="110"></ev-loader>
            </div>
            <div class="add-agent">
              <ev-input-reactive type="text" [formCtrl]="newAgentForm.get('newAgent')" (keyup.enter)="addNewAgent()"> </ev-input-reactive>
              <button (click)="addNewAgent()">Legg til ny</button>
            </div>
        </div>
    </div>
</ev-dialog>
