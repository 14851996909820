import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnergyLabelConnection } from '../../models/energy-label.model';
import { EnergyLabelService } from '../../services/energy-label.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { EVEstateType } from '../../../shared/enums/estatetype.enum';

@Component({
  selector: 'search-energy-connections',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './search-energy-connections.component.html',
  styleUrl: './search-energy-connections.component.scss'
})
export class SearchEnergyConnectionsComponent {
  query = new UntypedFormControl('');
  searchForm = this.fb.group({query: this.query});
  searchResult: EnergyLabelConnection[] = [];
  loading: boolean = false;
  EVEstateType = EVEstateType;
  
  @Output() selectedEnergyLabelConnection: EventEmitter<EnergyLabelConnection> = new EventEmitter<EnergyLabelConnection>();

  constructor(private fb: UntypedFormBuilder, public energyLabelService: EnergyLabelService,  private toastService: ToastService) { }

  search(): void {
    this.searchResult = [];
    
    if (!this.query.value) {
      return;
    }
    
    this.loading = true;
    this.energyLabelService.searchEnergyLabelConnections(this.query.value)
    .pipe(
      catchError(error => {
        console.error(error);
        this.toastService.setMessage({ text: 'Noe feil har skjedd ved søk. Prøv igjen', type: 'error' });
        this.loading = false;
        return of({});
      })
    )
    .subscribe(res => {
        this.searchResult = res as EnergyLabelConnection[];
        this.loading = false;
      },
    );
  }

  selectEnergyConnection(connection: EnergyLabelConnection) {
    this.selectedEnergyLabelConnection.emit(connection);
  }
}
