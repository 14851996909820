import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ToastService} from "../../shared/services/toast.service";
import {Sale, SaleQueryResponse, SaleWithUnits} from "../models/sale.model";
import {Unit} from "../models/unit.model";

@Injectable()
export class CommercialSaleService {
  constructor(private https: HttpClient, private toastService: ToastService) { }

  getSaleWithUnits(estateSaleId: number): Observable<SaleWithUnits> {
    const response = forkJoin([this.getSale(estateSaleId), this.getSaleUnits(estateSaleId)]).pipe(map(result =>
      {
        const response = {sale: result[0], estateSaleUnits: result[1]} as SaleWithUnits;
        return response
      }));

      return response;
  }

  getSale(estateSaleId: number): Observable<Sale[]> {
    return this.https.get<{ data:Sale[]}>(`${environment.apiUrl}/Commercial/Sales/${estateSaleId}`).pipe(
      map(result => result.data )
    );
  }

  getSaleUnits(estateSaleId: number): Observable<Unit[]> {
    return this.https.get<{ data: Unit[] }>(`${environment.apiUrl}/Commercial/sales/${estateSaleId}/units`).pipe(
      map(result => result.data )
    );
  }

  putSaleWithSaleUnits(
    estateSaleId: number,
    sale: Sale,
    addConnectedUnits: number[],
    removeConnectedUnits: number[]): Observable<number> {
      return forkJoin([
      this.updateSale(estateSaleId, sale),
      this.addSaleUnits(estateSaleId, addConnectedUnits),
      this.deleteSaleUnits(estateSaleId, removeConnectedUnits)
    ]).pipe(map(result => {
      return estateSaleId
    }));
  }
  
  addSale(sale: Sale) {
    return this.https.post(`${environment.apiUrl}/Commercial/Sales`, sale)
  }

  updateSale(estateSaleId: number, sale: Sale) {
    return this.https.put(`${environment.apiUrl}/Commercial/Sales/${estateSaleId}`, sale)
  }

  searchSale(query: string): Observable<SaleQueryResponse[]> {
    return this.https.get<{data : SaleQueryResponse[]}>(`${environment.apiUrl}/Commercial/Sales/Search?query=${query}`).pipe(map(response => response.data));
  }

  addSaleUnits(saleId: number, unitIds: number[]) {
      return this.https.put(`${environment.apiUrl}/Commercial/sales/${saleId}/units`, unitIds)
  }

  deleteSaleUnits(saleId: number, unitIds: number[]) {
      return this.https.delete(`${environment.apiUrl}/Commercial/sales/${saleId}/units`, { body: unitIds })
  }
}
