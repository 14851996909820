<div class="versions">
    <div class="version" *ngFor="let versionObj of versions" (click)="versionsChange(versionObj.version)">
        <div>
            <div *ngIf="versionObj.isApprovedForPublish">
                <div class="icon-check"></div>
            </div>
        </div>
        <div [ngClass]="{'selected': versionObj.version == selected}">
            {{ versionObj.version }}
        </div>
    </div>
</div>
