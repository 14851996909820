import { Component } from '@angular/core';
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {

  constructor(private authService: MsalService) { }

  account() {
    return this.authService.instance.getActiveAccount();
  }

  logout() {
    this.authService.logout();
  }
}
