import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Unit } from '../models/unit.model';

@Injectable()
export class ConnectedEstateUnitsService {

    /**
     * Observable for the current connected list
     */
    connectedSaleUnits$: Observable<Unit[]>;

    private _connectedUnits: BehaviorSubject<Unit[]>;
    private _originalList: Unit[];

    constructor() {
        this._connectedUnits  = new BehaviorSubject([]);
        this.connectedSaleUnits$ = this._connectedUnits.asObservable();
        this._originalList = [];
    }

    /**
     * Set the connected list and the original list. Creates an empty list if null/undefined
     */
    setConnectedUnitList(units: Unit[]): void {
        const initlist = units != null ? units : [];

        this._originalList = [...initlist];
        this._connectedUnits.next([...initlist]);
    }

    /**
     *  Add a unit to the connected list
     * @param unit
     */
    addConnectedUnit(unit: Unit): void {
        if(unit == null) {
            throw 'unit can not be null or undefined';
        }

        const connectedList = this._connectedUnits.value;
        const inList = this.isInCurrentList(unit);

        if(!inList) {
            connectedList.push(unit);
        }

        this._connectedUnits.next(connectedList);
    }

    /**
     * Remove a unit from the connected list
     * @param unit
     */
    removeConnectedUnit(unit: Unit): void {
        const connectedList = this._connectedUnits.value;
        const idx = connectedList.findIndex(p => p.unitId === unit?.unitId);

        if(idx != -1) {
            connectedList.splice(idx, 1);
        }

        this._connectedUnits.next([...connectedList]);
    }

    /**
     * Get the current units in the connected list
     */
    getConnectedUnits(): Unit[] {
        return this._connectedUnits.value;
    }

    /**
     * Get the current units ids in the connected list
     */
    getConnectedUnitsId(): number[] {
      return this.getConnectedUnits().map( p => { return p.unitId });
    }

    /**
     * Get the added units in the connected list that are not in the original list
     */
    getAddedUnits(): Unit[] {
        return this._connectedUnits.value.filter(p => !this.isInOriginalList(p));
    }

    /**
     * Get the current units ids in the connected list that are not in the original list
     */
    getAddedConnectedUnitsId(): number[] {
      return this.getAddedUnits().map( p => { return p.unitId });
    }

    /**
     * Returns the removed units by comparing the current connected list with the original list
     */
    getRemovedUnits(): Unit[] {
        const removedOriginals = this._originalList.filter(p => !this.isInCurrentList(p));
        return removedOriginals;
    }

    /**
     * Returns the removed units ids by comparing the current connected list with the original list
     */
    getRemovedUnitsId(): number[] {
      const removedOriginals = this.getRemovedUnits().map(p => { return p.unitId })
      return removedOriginals;
    }

    private isInCurrentList(unit: Unit): boolean {
       return this._connectedUnits.value.find(p => p.unitId === unit?.unitId) != undefined;
    }

    private isInOriginalList(unit: Unit): boolean {
        return this._originalList.find(p => p.unitId === unit?.unitId) != undefined;
     }
}
