import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {PhotoRegistrationService} from "../../services/photo-registration.service";
import { Unit } from "../../../commercial-sale/models/unit.model";
import {ToastService} from "../../../shared/services/toast.service";
import {debounceTime, distinctUntilChanged, filter, switchMap, tap} from "rxjs/operators";

@Component({
  selector: 'photo-registration-page',
  templateUrl: './photo-registration-page.component.html',
  styleUrls: ['./photo-registration-page.component.scss']
})
export class PhotoRegistrationPageComponent implements OnInit {
  query = new UntypedFormControl('');
  searchForm = this.fb.group({ query: this.query });
  loading: boolean = false;
  selectedRealEstate: Unit = null;
  realEstates: Unit[] | undefined;
  images: any[];
  minSearchLength: number = 3;

  imageForm = new UntypedFormGroup({
    image: new UntypedFormControl('', [Validators.required]),
  });

  constructor(private toastService: ToastService, private fb: UntypedFormBuilder, private photoRegistrationService : PhotoRegistrationService) { }

  ngOnInit() {
    this.query.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.realEstates = [];
          this.loading = true;
        }),
        switchMap(async (searchInput) =>  {
            this.searchRealEstate(searchInput)
          }
        )
      ).subscribe()

  }

  searchRealEstate(searchInput: string) {
    if (searchInput.length >= this.minSearchLength) {
      this.photoRegistrationService.searchEstates(searchInput).subscribe(
        (response) => {
          this.loading = false;
          this.realEstates = response;
        },
        () => {
          this.loading = false;
          this.toastService.setMessage({ text: 'Det oppstod en feil ved søk på Eiendom. Prøv på nytt', type: 'error'})
        })
    }
    else {
      this.loading = false;
    }
  }

  setSelectedRealEstate(selectedEstate: Unit) {
    this.selectedRealEstate = selectedEstate;
    const estate = selectedEstate.streetName + ' ' + selectedEstate.streetNumber + selectedEstate.streetLetter + ', ' + selectedEstate.postalCode + ' ' + selectedEstate.postOffice;
    this.searchForm.patchValue({query: estate}, {emitEvent: false, onlySelf: true});
    this.loadImages(selectedEstate.estateId);
    this.realEstates = [];
  }

  onImageChange(event: any): void {
    if (event.target.files.length > 0) {
      for(let file of event.target.files) {
        this.photoRegistrationService.postImage({ estateId: this.selectedRealEstate.estateId, image: file }).subscribe(() => {
          this.loadImages(this.selectedRealEstate.estateId);
        })
      }
    }
  }

   loadImages(estateId: number){
    this.photoRegistrationService.getImages(estateId).subscribe(
      (response: { data: any[]; }) => {
        this.images = response.data;
        this.images.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
      })
  }

  setMainImage(filename: string) {
    const estateId = this.selectedRealEstate.estateId;
    this.photoRegistrationService.postMainImage(estateId, filename).subscribe(
      (response: any) => {
        const objectIndex = this.images.findIndex((obj => obj.main == true));
        this.images[objectIndex].main = false;
        this.updateImageArray(response.data);
      })
  }

  setArchivedImage(filename: string) {
    const estateId = this.selectedRealEstate.estateId;
    this.photoRegistrationService.postArchiveImage(estateId, filename).subscribe(
      (response: any) => {
        this.updateImageArray(response.data);
      })
  }

  setActiveImage(filename: string) {
    const estateId = this.selectedRealEstate.estateId;
    this.photoRegistrationService.postRecoverImage(estateId, filename).subscribe(
      (response: any) => {
        this.updateImageArray(response.data);
      })
  }

  updateImageArray(updatedObject: any): void {
    const objectIndex = this.images.findIndex((obj => obj.filename == updatedObject.filename));
    this.images[objectIndex] = updatedObject;
  }

}
