import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'finn-text',
  templateUrl: './finn-text.component.html',
  styleUrls: ['./finn-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdTextComponent {
  @Input() adtext: string;
}
