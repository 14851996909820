import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Agent } from "../models/agent.model";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AgentsService {

  constructor(private http: HttpClient) { }

  searchAgents(searchQuery: string): Observable<Agent[]> {
    return this.http.get<{data: Agent[]}>(`${environment.apiUrl}/estateagents/search?limit=201&offset=1&query=${searchQuery}`).pipe(
      map(result => result.data)
    );
  }

  addAgent(agentInput: String): Observable<Agent> {
    return this.http.post<{data: Agent}>(`${environment.apiUrl}/estateagents`, { name: agentInput }).pipe(
      map(result => result.data)
    )
  }
}
