
<side-nav
  [sidenavTemplateRef]="navContent"
  [navWidth]="280"
  [duration]="0.5">
</side-nav>

<div class="main-container">
  <section class="header">
    <button (click)="toggleSideNav()" class="menu-button">
    </button>
    <h1>TOOLKIT</h1>
    <span>{{ pageTitle }}</span> 
  </section>
  <section class="content">
    <router-outlet></router-outlet>
  </section>
</div>

<div *ngFor="let message of messages">
  <ev-toast [message]="message" (deleteToast)="removeMessage(message)"></ev-toast>
</div>

<ng-template #navContent>
  <ul class="nav">
    <li><a routerLink="/">Dashboard</a></li>
    <li><a routerLink="/commercialsale">Næringssalg</a></li>
    <li><a routerLink="/organizationsubunits">Enhetsregisteret</a></li>
    <li><a routerLink="/commercialrent">Næringsutleie</a></li>
    <li><a routerLink="/photoregistration">Fotoregistrering</a></li>
    <li><a routerLink="/estatetypecorrection">Korrigering eiendomstype</a></li>
    <li><a routerLink="/energylabels">Energimerker</a></li>
  </ul>
</ng-template>



