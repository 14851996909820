<div class="component-wrapper">
  <div class="search-wrapper">
    <ev-input-reactive type="search" [formCtrl]="searchForm.get('query')" (keyup.enter)="search()"> </ev-input-reactive>
    <button class="search" (click)="search()"></button>
  </div>
  <div *ngIf="!loading" class="search-result-wrapper">
    <table *ngIf="searchResult && searchResult.length > 0; else noResult">
      <tr class="row-header">
        <th class="type"></th>
        <th>Orgnummer</th>
        <th class="name">Navn</th>
        <th>Kommune</th>
      </tr>
      <tr class="row-item" title="{{'uid: ' + unit.id}}" *ngFor="let unit of searchResult" (click)="onSelectSubUnit(unit)">
        <td><div class="estate-icon"></div></td>
        <td class="address">{{ unit.organizationNumber }} </td>
        <td>{{ unit.organizationName }}</td>
        <td>{{ unit.organizationMunicipality }}</td>
      </tr>
    </table>
    <ng-template #noResult>
      <div class="no-result">Ingen resultater</div>
    </ng-template>
  </div>
  <div *ngIf="loading" class="search-result-wrapper loader">
    <ev-loader [size]="110"></ev-loader>
  </div>
</div>
