import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstateTypeCorrectionPageComponent } from './pages/estate-type-correction-page/estate-type-correction-page.component';
import { EvKeyValuePairModule } from "ev-key-value-pair";
import { EvDropdownReactiveModule } from "ev-dropdown-reactive";
import { EvInputReactiveModule } from "ev-input-reactive";
import { EvDialogModule } from "ev-dialog";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    EstateTypeCorrectionPageComponent
  ],
  imports: [
    CommonModule,
    EvKeyValuePairModule,
    EvDropdownReactiveModule,
    EvInputReactiveModule,
    EvDialogModule,
    SharedModule
  ]
})
export class EstateTypeCorrectionModule { }
