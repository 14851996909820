<ev-dialog [visible]="showModal" (close)="closeModal()" [theme]="'green'" [title]="title" [tooltip]="tooltip" [hideFooter]="true">
  <div class="search-modal">
    <div class="header">
      <ev-input-reactive type="search" [formCtrl]="searchForm.get('query')" (keyup.enter)="searchQuery()"> </ev-input-reactive>
      <button class="search" (click)="searchQuery()"></button>
    </div>

    <div class="search-result-wrapper">
      <ng-content>

      </ng-content>
      <div *ngIf="loading" class="search-result-wrapper loader">
        <ev-loader [size]="110"></ev-loader>
      </div>
    </div>

  </div>
</ev-dialog>
