import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { filter, map, tap } from "rxjs/operators";
import { EstateTypeSubType } from '../models/estateTypeSubType';

@Injectable({
  providedIn: 'root'
})
export class ReferenceTypesService {

  private estateTypeSubTypes: EstateTypeSubType[] = [];

  constructor(private https: HttpClient) { }

  getSource(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/Source`).pipe(
      map(result => result.data));
  }
  getEstateTypeSubTypes(estateType?: number): Observable<EstateTypeSubType[]> {
    let obs: Observable<EstateTypeSubType[]> = null;

    if(this.estateTypeSubTypes.length === 0) {
      obs = this.https.get<{data: EstateTypeSubType[]}>(`${environment.apiUrl}/ReferenceTypes/EstateTypeSubTypes`).pipe(tap(result => this.estateTypeSubTypes = result.data),
        map(result => result.data));
    } else {
      obs = of([...this.estateTypeSubTypes]);
    }

    if(estateType != null) {
      return obs.pipe(map(res => res.filter(e => e.id === estateType)));
    } else {
      return obs;
    }
  }
  getParking(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/Parking`).pipe(
      map(result => result.data));
  }
  getTechnicalStandard(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/TechnicalStandard`).pipe(
      map(result => result.data));
  }
  getBREEAMCertificateRatings(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/BREEAMCertificateRatings`).pipe(
      map(result => result.data));
  }
  getRentalStatus(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/RentalStatus`).pipe(
      map(result => result.data));
  }
  getEnergyLabelCertificateMethods(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/EnergyLabelCertificateMethods`).pipe(
      map(result => result.data));
  }
  getBuildingMaterials(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/BuildingMaterials`).pipe(
      map(result => result.data));
  }
  getEnergyLabelingHeatingColors(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/EnergyLabelingHeatingColors`).pipe(
      map(result => result.data));
  }
  getEnergyBuildingCategories(): Observable<[]> {
    return this.https.get<{data: []}>(`${environment.apiUrl}/ReferenceTypes/EnergyBuildingCategories`).pipe(
      map(result => result.data));
  }
}
