import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { Unit } from "../../commercial-sale/models/unit.model";
import { map } from "rxjs/operators";
import { Rent, RentWithUnits } from "../models/rent";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SearchRent } from "../models/searchRent";

@Injectable({
  providedIn: 'root'
})

export class CommercialRentService {

  connectedRentUnits$: Observable<Unit[]>;

  private _connectedUnits: BehaviorSubject<Unit[]>;
  private _originalList: Unit[];

  constructor(private https: HttpClient) {
    this._connectedUnits  = new BehaviorSubject([]);
    this.connectedRentUnits$ = this._connectedUnits.asObservable();
    this._originalList = [];
  }

  getRentWithUnits(estateSaleId: number): Observable<RentWithUnits> {
    const response = forkJoin([this.getRent(estateSaleId), this.getRentUnits(estateSaleId)]).pipe(map(result =>
    {
      const response = result[0] as RentWithUnits;
      response.estateRentUnits = result[1];
      return response
    }));

    return response;
  }

  getRent(estateRentId: number): Observable<Rent> {
    return this.https.get<{data: Rent}>(`${environment.apiUrl}/Commercial/RentalEstates/${estateRentId}`)
        .pipe(map(result => result.data ));
  }

  getRentUnits(estateRentId: number): Observable<Unit[]> {
    return this.https.get<{ data: Unit[] }>(`${environment.apiUrl}/Commercial/RentalEstates/${estateRentId}/units`)
        .pipe(map(result => result.data));
  }

  putRentWithSaleUnits(
    estateSaleId: number,
    rent: Rent,
    addConnectedUnits: number[],
    removeConnectedUnits: number[]): Observable<number> {
    return forkJoin([
      this.updateRent(estateSaleId, rent),
      this.addRentUnits(estateSaleId, addConnectedUnits),
      this.deleteRentUnits(estateSaleId, removeConnectedUnits)
    ]).pipe(map(result => {
      return estateSaleId
    }));
  }

  addRent(rent: Rent) {
    return this.https.post(`${environment.apiUrl}/Commercial/RentalEstates`, rent)
  }

  updateRent(estateRentId: number, rent: Rent) {
    return this.https.put(`${environment.apiUrl}/Commercial/RentalEstates/${estateRentId}`, rent)
  }

  searchRent(query: string): Observable<SearchRent[]> {
    return this.https.get<{data : SearchRent[]}>(`${environment.apiUrl}/Commercial/RentalEstates/Search?query=${query}`).pipe(map(response => response.data));
  }

  addRentUnits(rentId: number, unitIds: number[]) {
    return this.https.put(`${environment.apiUrl}/Commercial/RentalEstates/${rentId}/units`, unitIds)
  }

  deleteRentUnits(rentId: number, unitIds: number[]) {
    return this.https.delete(`${environment.apiUrl}/Commercial/RentalEstates/${rentId}/units`, { body: unitIds })
  }



  setConnectedUnitList(units: Unit[]): void {
    const initlist = units != null ? units : [];

    this._originalList = [...initlist];
    this._connectedUnits.next([...initlist]);
  }

  /**
   *  Add a unit to the connected list
   * @param unit
   */
  addConnectedUnit(unit: Unit): void {
    if(unit == null) {
      throw 'unit can not be null or undefined';
    }

    const connectedList = this._connectedUnits.value;
    const inList = this.isInCurrentList(unit);

    if(!inList) {
      connectedList.push(unit);
    }

    this._connectedUnits.next(connectedList);
  }

  /**
   * Remove a unit from the connected list
   * @param unit
   */
  removeConnectedUnit(unit: Unit): void {
    const connectedList = this._connectedUnits.value;
    const idx = connectedList.findIndex(p => p.unitId === unit?.unitId);

    if(idx != -1) {
      connectedList.splice(idx, 1);
    }

    this._connectedUnits.next([...connectedList]);
  }

  resetConnectedUnits(): void {
    this._connectedUnits.next([]);
  }

  /**
   * Get the current units in the connected list
   */
  getConnectedUnits(): Unit[] {
    return this._connectedUnits.value;
  }

  /**
   * Get the current units ids in the connected list
   */
  getConnectedUnitsId(): number[] {
    return this.getConnectedUnits().map( p => { return p.unitId });
  }

  /**
   * Returns the removed units by comparing the current connected list with the original list
   */
  getRemovedUnits(): Unit[] {
    const removedOriginals = this._originalList.filter(p => !this.isInCurrentList(p));
    return removedOriginals;
  }

  /**
   * Returns the removed units ids by comparing the current connected list with the original list
   */
  getRemovedUnitsId(): number[] {
    const removedOriginals = this.getRemovedUnits().map(p => { return p.unitId })
    return removedOriginals;
  }

  private isInCurrentList(unit: Unit): boolean {
    return this._connectedUnits.value.find(p => p.unitId === unit?.unitId) != undefined;
  }
}
