import { Injectable } from '@angular/core';
import { ToastService } from "../../shared/services/toast.service";
import { HttpClient } from "@angular/common/http";
import { forkJoin, Observable, of } from "rxjs";
import { OrganizationSubUnit, OrganizationSubUnitWithUnits } from "../../dashboard/models/organization-sub-units";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { SubUnitResponse } from "../../shared/components/search-unit/search-unit.component";
import { Unit } from 'src/app/shared/models/unit.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSubUnitsService {

  constructor(private https: HttpClient, private toastService: ToastService) { }

  getOrganizationSubUnitWithUnits(subUnitId: number): Observable<OrganizationSubUnitWithUnits> {
    const response = forkJoin([this.getOrganizationSubUnit(subUnitId), this.getEstateUnits(subUnitId)]).pipe(map(result =>
      {
        const response = result[0] as OrganizationSubUnitWithUnits;
        response.unitIds = result[1];
        return response
      }));

      return response;
  }

  getOrganizationSubUnit(subUnitId: number): Observable<OrganizationSubUnit> {
    return this.https.get<{data: OrganizationSubUnit}>(`${environment.apiUrl}/Commercial/OrganizationSubUnits/${subUnitId}`).pipe(map((res) => {return res.data} ));
  }

  updateOrganizationSubUnitWithEstateUnits(
    subUnitId: number, 
    webPageUrl: string,
    addConnectedUnits: number[],
    removeConnectedUnits: number[]): Observable<number> {
    return forkJoin([
      this.updateOrganizationSubUnit(subUnitId, webPageUrl),
      this.addEstateUnits(subUnitId, addConnectedUnits),
      this.deleteEstateUnits(subUnitId, removeConnectedUnits)
    ]).pipe(map(result => {
      return subUnitId
    }));
  }

  updateOrganizationSubUnit(orgUnitId: number, webPageUrl: string) {
    return this.https.put(`${environment.apiUrl}/Commercial/OrganizationSubUnits/${orgUnitId}`, { webPageUrl} )
  }

  searchOrganizationSubUnits(query: number): Observable<SubUnitResponse[]> {
    return this.https.get<{data: SubUnitResponse[]}>(`${environment.apiUrl}/Commercial/OrganizationSubUnits/Search?limit=200&offset=1&query=${query}`).pipe(map(res => res.data))
  }

  getEstateUnits(subUnitId: number): Observable<Unit[]> {
    return this.https.get<{data: Unit[]}>(`${environment.apiUrl}/Commercial/OrganizationSubUnits/${subUnitId}/EstateUnits`).pipe(map((res) => {return res.data} ));
  }

  addEstateUnits(subUnitId: number, unitIds: number[]): Observable<number[]> {
    if(unitIds != null && unitIds.length > 0) {
      return this.https.post<{data: number[]}>(`${environment.apiUrl}/Commercial/OrganizationSubUnits/${subUnitId}/EstateUnits`, unitIds).pipe(map((res) => {return res.data} ));
    } else {
      return of(null);
    }
  }

  deleteEstateUnits(subUnitId: number, unitIds: number[]): Observable<number[]> {
    if(unitIds != null && unitIds.length > 0) {
      return this.https.delete<{data: number[]}>(`${environment.apiUrl}/Commercial/OrganizationSubUnits/${subUnitId}/EstateUnits`, { body: unitIds }).pipe(map((res) => {return res.data} ));
    } else {
      return of(null);
    }
  }
}