import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AgentsService } from "../../services/agents.service";
import { Agent } from "../../models/agent.model";
import {ToastService} from "../../../shared/services/toast.service";
import {ITooltip} from "ev-tooltip";
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'search-agent',
  templateUrl: './search-agent.component.html',
  styleUrls: ['./search-agent.component.scss']
})
export class SearchAgentComponent implements OnInit {

  @Output() selectedAgentEvent = new EventEmitter<Agent>();
  @Input() title: string;

  query = new UntypedFormControl('');
  searchForm = this.fb.group({ query: this.query });

  newAgent = new UntypedFormControl('');
  newAgentForm = this.fb.group({ newAgent: this.newAgent });


  showModal: boolean = false;
  agents: Agent[] | undefined;
  searchAgentInput: string = '';
  searchAgentTooltip: ITooltip = {
    show: false,
    header: '',
    info: [
      'Skriv inn deler av eller hele meglernavnet du søker etter. Vær oppmerksom på at her skal vi registrere hovedkontor/meglerkjede. Står det Privatmegleren Avenue i annonsen, søker du frem Privatmegeleren. Den finner du for eksempel ved å søke "privat".',
      'Hvis du ikke finner det skriv det inn på nederste og trykk Legg til ny. Vær helt sikker på skrivemåte når du gjør dette slik at vi ikke får dubletter i databasen.',
    ],
    width: 300
  }
  loading: boolean = false;

  constructor(private agentsService: AgentsService, private toastService: ToastService, private fb: UntypedFormBuilder,) { }

  ngOnInit(): void {
  }

  searchAgent() {
    const searchString = this.query.value;

    if(searchString !== '' && searchString !== null) {
      this.loading = true;
      this.agentsService.searchAgents(searchString).subscribe(
        (response) => {
          this.agents = response;
          this.loading = false;
        },
        (error) => {
          this.toastService.setMessage({text: 'Noe feil har skjedd ved å søke etter meglere. Prøv igjen', type: 'error'});
          this.loading = false;
      })
    }
  }

  setSelectedAgent(selectedAgent: Agent) {
    this.selectedAgentEvent.emit(selectedAgent);
    this.showModal = false;
    this.searchAgentInput = '';
  }

  addNewAgent() {
    const addedAgent = this.newAgent.value;

    if(addedAgent !== '' && addedAgent != null && !this.loading) {
      this.loading = true;
      this.agentsService.addAgent(addedAgent).subscribe(
        (agent) => {
          this.setSelectedAgent(agent);
          this.loading = false;
        },
        () => {
          this.toastService.setMessage({text: 'Noe feil har skjedd ved å legge til ny megler. Prøv igjen', type: 'error'})
        })
    }
  }
}
