<div class="component-wrapper">
  <div class="search-wrapper">
    <ev-input-reactive type="search" [formCtrl]="searchForm.get('query')" (keyup.enter)="search()"> </ev-input-reactive>
    <button class="search" (click)="search()"></button>
  </div>
  <div *ngIf="!loading" class="search-result-wrapper">
    <div *ngIf="searchResult && searchResult.length > 0; else noResult" class="address-header">
      <div class="type-heading">Type</div>
      <div class="address-heading">Adresse</div>
    </div>
    <ng-template #noResult>
      <div class="no-result">Ingen resultater</div>
    </ng-template>
    <div *ngFor="let addressGroup of searchResult" class="address-group">
      <div class="address">
        <div class="estate-icon" [ngClass]="iconClass(11)"></div>
        <div>
          {{ addressGroup.address }}
        </div>
      </div>
      <div *ngFor="let item of addressGroup.list" class="item">
                <span>
                    {{ (item.informationDate | date: "d MMM y") + " - " + item.cadastre }}
                  <a class="finn-link" [href]="item.sourceURL" target="_blank">
                        <u>{{ SourceTypeId[item.sourceTypeID] }}</u>
                    </a>
                </span>
        <button class="light" (click)="onSelectRent(item.estateForRentId)">velg</button>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="search-result-wrapper loader">
    <ev-loader [size]="110"></ev-loader>
  </div>
</div>
