<div class="dashboard-header">
  <p>Velkommen {{ account()?.name }}</p>
  <button (click)="logout()">Logg ut</button>
</div>

<h2>Mine Toolkits</h2>

<div class="toolkits-wrapper">
  <div class="toolkit-item" [routerLink]="'/commercialsale'">
    <p>Næringssalg</p>
  </div>
  <div class="toolkit-item" [routerLink]="'/organizationsubunits'">
    <p>Enhetsregisteret</p>
  </div>
  <div class="toolkit-item" [routerLink]="'/commercialrent'">
    <p>Næringsutleie</p>
  </div>
  <div class="toolkit-item" [routerLink]="'/photoregistration'">
    <p>Fotoregistrering</p>
  </div>
  <div class="toolkit-item" [routerLink]="'/estatetypecorrection'">
    <p>Korrigering eiendomstype</p>
  </div>
  <div class="toolkit-item" [routerLink]="'/energylabels'">
    <p>Energimerker</p>
  </div>
</div>
