<div class="table-wrapper">
  <h1 *ngIf="title">{{ title }}</h1>
  <table *ngIf="rows && rows.length > 0; else NoTableData">
    <tr>
      <th *ngFor="let header of headers" [ngStyle]="{'text-align': header.textAlign }"> {{ header.title }}</th>
    </tr>
    <tr *ngFor="let row of rows">
      <td *ngFor="let header of headers" [ngStyle]="{'text-align': header.textAlign }">
        <span *ngIf="row[header.key]; else NoResult">{{ row[header.key] }}</span>
        <ng-template #NoResult><span>-</span></ng-template>
      </td>
    </tr>
  </table>
</div>

<ng-template #NoTableData><p class="no-data">Ingen data funnet</p></ng-template>

