import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { CommercialRentService } from "../../services/commercial-rent.service";
import { ToastService } from "../../../shared/services/toast.service";
import { SourceTypeId } from 'src/app/commercial-sale/models/source-type-id.enum';
import { SearchRent } from "../../models/searchRent";
import { EVEstateType } from "../../../shared/enums/estatetype.enum";

@Component({
  selector: 'search-rent',
  templateUrl: './search-rent.component.html',
  styleUrls: ['./search-rent.component.scss', '../../../../assets/styles/search-modal.scss']
})
export class SearchRentComponent implements OnInit {
  @Output() selectedRent: EventEmitter<number> = new EventEmitter();

  query = new UntypedFormControl('');
  searchForm = this.fb.group({query: this.query});
  searchResult: SearchRent[] = [];
  SourceTypeId = SourceTypeId;
  loading: boolean = false;

  constructor(private fb: UntypedFormBuilder, private commercialRentService: CommercialRentService, private toastService: ToastService) { }

  ngOnInit(): void {
  }

  search(): void {
    this.searchResult = [];
    this.loading = true;
    if (this.query.value !== '' && this.query.value != null && this.loading) {
      this.commercialRentService.searchRent(this.query.value).subscribe(
        (res) => {
          this.searchResult = res;
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.toastService.setMessage({text: 'Noe feil har skjedd ved søk. Prøv igjen', type: 'error'})
        },
      )
    }
  }

  onSelectRent(estateRentId: number | undefined): void {
    this.selectedRent.emit(estateRentId);
  }

  iconClass(id: number): string {
    return EVEstateType[id];
  }
}
