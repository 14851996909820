<div class="component-wrapper">
  <div class="button-collection">
    <div class="button-header row">
      <button [disabled]="loadingNextWorkqueueItem || currentWorkItem != null" (click)="showSearchUnitDialog = true">Søk virksomhet</button>
      <button class="workqueue" [disabled]="loadingNextWorkqueueItem || currentWorkItem != null" (click)="getNextFromWorkQueue()">{{'Neste fra Workqueue (' + remainingWorkqueue + ')'}}</button>
    </div>

    <div class="row">
      <div class="expert">
        <div class="instruction-line">
          <div class="instruction">{{ currentWorkItem?.instruction }} </div>
          <div>{{currentWorkItem?.info}}</div>
        </div>
        <button (click)="putCommonWorkQueueHold()">Sett til ekspert</button>
      </div>
    </div>
  </div>

  <div class="header">Virksomhet</div>
  <div class="sub-unit-row row">
    <div *ngIf="selectedOrganization == null">Søk opp en virksomhet eller bruk Workqueue</div>
    <div>{{ selectedOrganization?.organizationNumber }}</div>
    <div>{{ selectedOrganization?.organizationName }}</div>
  </div>
  <div class="sub-unit-row row">
    <div>{{ selectedOrganization?.visitAddress}}</div>
  </div>

  <div class="webUrl" *ngIf="selectedOrganization != null">
    <ev-key-value-pair [key]="'Nettside'" [width]="'150px'">
      <ev-input-reactive
        [type]="'text'"
        [hideReset]="true"
        [formCtrl]="organizationSubUnitsForm.get('webPageUrl')"
      ></ev-input-reactive>
      <button [disabled]="!organizationSubUnitsForm.controls['webPageUrl'].value" (click)="openWebPage()">Åpne</button>
    </ev-key-value-pair>
  </div>


  <div class="form__column">
    <search-estate [query]="splitVisitAddress(selectedOrganization?.visitAddress)" (selectedRealEstateEvent)="addConnectedRealEstate($event)"></search-estate>
    <ul>
      <li *ngFor="let estateUnit of (connectedEstateUnits$ | async)">
        <div title="{{'uid: ' + estateUnit.unitId}}">
          <div>{{ estateUnit.streetName }} {{ estateUnit.streetNumber }}{{ estateUnit.streetLetter }}
            , {{ estateUnit.postalCode }} {{ estateUnit.postOffice }}</div>
          <div>{{ estateUnit.cadastre }}</div>
        </div>
        <button class="light" (click)="deleteConnectedRealEstate(estateUnit)">Fjern</button>
      </li>
    </ul>
    <div class="map-wrapper">
      <static-map [mapMarkers]="connectedEstateUnitsMapMarkers$ | async" [index]="1"></static-map>
    </div>
  </div>

  <div class="footer-buttons">
    <button [disabled]="!selectedOrganization || isSaving" (click)="saveOrganizationSubUnit()">Lagre</button>
    <button (click)="cancel()">Avbryt</button>
    <div *ngIf="isSaving">Lagret endringer</div>
  </div>
</div>

<ev-dialog [title]="'Søk etter Virksomhet'" [theme]="'green'" [visible]="showSearchUnitDialog" (close)="showSearchUnitDialog = false" [hideFooter]="true">
  <search-unit (selectedSubUnit)="navigateToWorkItem($event.id)"></search-unit>
</ev-dialog>
